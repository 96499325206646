import { useEffect, useState } from "react";
import sanityClient from "../../client";
import "./index.css";

export const PostHero = () => {
  const [data, setData] = useState([]);
  const [quoteBlockData, setQuoteBlockData] = useState([]);
  const [closeBlock, setCloseBlock] = useState(false);

  const fetchData = async () => {
    try {
      const query = `*[_type == 'postHero'][0]`;
      const result = await sanityClient.fetch(query);
      setData(result);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCtaData = async () => {
    try {
      const query = `*[_type == 'fixedCtas'][0]`;
      const result = await sanityClient.fetch(query);
      setQuoteBlockData(result.quoteBlock);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchCtaData();
  }, []);

  return (
    <>
      <section id="about-us" className="post-hero">
        <h1>Why Jungle Lion Security?</h1>
        {data && data.text && <h2>{data.text}</h2>}
        {quoteBlockData && (
          <div className="button-container">
            {quoteBlockData.applyCta && quoteBlockData.quoteCta ? (
              <>
                <button className="apply">
                  <a href={`mailto:${quoteBlockData.applyCta.url}`}>
                    {quoteBlockData.applyCta.text}
                  </a>
                </button>
                <button className="quote">
                  <a href={`/#contact`}>{quoteBlockData.quoteCta.text}</a>
                </button>
              </>
            ) : null}
          </div>
        )}
      </section>
    </>
  );
};
