import { useEffect, useState } from "react";
import sanityClient from "../../client";

import "./index.css";

const testimonialsData = [
  {
    text: "The security team provided by this company has been nothing short of exceptional. Their attention to detail and professionalism have given us peace of mind, knowing that our business is protected around the clock. I highly recommend their services to anyone in need of top-notch security.",
    author: "John D. - Business Owner",
  },
  {
    text: "We hired this security company to safeguard our home, and they exceeded our expectations. Their staff is always punctual, courteous, and highly skilled. We've never felt safer in our own home, thanks to their outstanding service.",
    author: "Sarah M. - Homeowner",
  },
  {
    text: "Organizing large events comes with its own set of security challenges, but this company made it look easy. Their team was efficient, proactive, and incredibly professional. Our event went off without a hitch, and we owe much of that success to their expert security services.",
    author: "Mark T. - Event Organizer",
  },
  {
    text: "As a property manager, ensuring the safety of our residents is my top priority. This security company has been a game-changer for us. Their consistent presence and swift response times have significantly reduced incidents on our property. They are truly the best in the business.",
    author: "Emily R. - Property Manager",
  },
  {
    text: "We experienced a few security breaches before hiring this company, but since then, we've seen a remarkable improvement. Their comprehensive security solutions and vigilant staff have made a world of difference. Their commitment to our safety and satisfaction is unparalleled.",
    author: "David S. - Retail Store Owner",
  },
];

export const Testimonials = () => {
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTestimonialIndex(
        (prevIndex) => (prevIndex + 1) % testimonialsData.length
      );
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <section id="testimonials" className="testimonials">
        <h1>Why People Choose Jungle Lion Security</h1>
        <div className="testimonial">
          <p>"{testimonialsData[currentTestimonialIndex].text}"</p>
          <p className="author">
            {testimonialsData[currentTestimonialIndex].author}
          </p>
        </div>
      </section>
    </>
  );
};
