import { useEffect, useState } from "react";
import sanityClient from "../../client";

import "./index.css";

export const Services = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const query = `*[_type == 'services'][0]`;
      const result = await sanityClient.fetch(query);
      setData(result.serviceSection);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section id="services" className="services">
        <div className="section-text">
          <div className="services-container">
            <div className="service-card">
              <img
                src="https://static3.depositphotos.com/1003587/163/i/450/depositphotos_1630487-stock-photo-security-guard.jpg"
                loading="lazy"
              />
              <div className="card-content">
                <h2>Personal Security</h2>
                <p>
                  We provide premium personal security services tailored for
                  executives, high-profile individuals, and celebreties
                </p>
                <a href="/services#personal-security">Learn More</a>
              </div>
            </div>
            <div className="service-card">
              <img
                src="https://t4.ftcdn.net/jpg/05/41/71/89/360_F_541718941_A57uBltD3aWmCL196DNujDYR6qq0UenW.jpg"
                loading="lazy"
              />
              <div className="card-content">
                <h2>Building & Site Security</h2>
                <p>
                  Safeguard your corporate offices, residential properties,
                  venues, and more with our reliable building and site security
                  solutions.
                </p>
                <a href="/services#building-and-site-security">Learn More</a>
              </div>
            </div>
            <div className="service-card">
              <img src="https://www.centralprotection.ca/wp-content/uploads/2022/09/event-security-1024x640.jpg" />
              <div className="card-content">
                <h2>Special Event Security</h2>
                <p>
                  Whether you are hosting a corporate gathering, private
                  function, or high-profile event, our specialized team excels
                  at providing comprehensive safety planning and onsite
                  security.
                </p>
                <a href="/services#special-event-security">Learn More</a>
              </div>
            </div>
          </div>
          <div className="button-container">
            {data && data.sectionButton && (
              <button className="button">
                <a href={data.sectionButton.slug}>{data.sectionButton.text}</a>
              </button>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
