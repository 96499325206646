import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

import "./index.css";

import MENU_ICON from "../../Assets/Icons/text.png";
import CLOSE_ICON from "../../Assets/Icons/close.svg";

export const MobileHeader = () => {
  const [pathname] = useState(window.location.href);
  const [menuData, setMenuData] = useState([]);
  const [logo, setLogo] = useState([]);
  const [showMenu, setShowMenu] = useState(false);

  const [applyButtonData, setApplyButtonData] = useState([]);

  const builder = ImageUrlBuilder(sanityClient);

  const urlFor = (source) => {
    return builder.image(source);
  };

  const fetchCtaData = async () => {
    try {
      const query = `*[_type == 'fixedCtas'][0]`;
      const result = await sanityClient.fetch(query);
      setApplyButtonData(result.applyButton);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const query = `*[_type == 'header'][0]`;
      const result = await sanityClient.fetch(query);
      const combinedMenuItems = [
        ...result.menu.leftSideItems,
        ...result.menu.rightSideItems,
      ];
      setMenuData(combinedMenuItems);
      setLogo(result.logo);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCtaData();
    fetchData();
  }, []);

  return (
    <>
      <header className="mobile-header">
        <nav>
          {logo && logo.asset && (
            <a href="/">
              <img className="logo" src={urlFor(logo.asset._ref).url()} />
            </a>
          )}
          <img
            className={`menu-icon ${!showMenu ? "hamburger" : "close"}`}
            src={!showMenu ? MENU_ICON : CLOSE_ICON}
            onClick={() => setShowMenu(!showMenu)}
          />
        </nav>
        <div className={`menu-overlay ${showMenu ? "show" : ""}`}>
          <ul className="menu-list">
            {menuData &&
              menuData.map((item) => {
                const isGalleryOrServices =
                  window.location.href.includes("/gallery") ||
                  window.location.href.includes("/services");

                const isAboutUsOrTestimonials =
                  item.menuItemString === "about us" ||
                  item.menuItemString === "testimonials";

                // if (isGalleryOrServices && isAboutUsOrTestimonials) {
                //   return null;
                // }

                return (
                  <li onClick={() => setShowMenu(false)} key={item._key}>
                    <a
                      href={
                        item.menuItemAnchor.current === "gallery"
                          ? "/gallery"
                          : item.menuItemAnchor.current === "/"
                          ? "/"
                          : item.menuItemAnchor.current === "services"
                          ? "/services"
                          : `#${item.menuItemAnchor.current}`
                      }
                    >
                      {item.menuItemString}
                    </a>
                  </li>
                );
              })}

            {applyButtonData && (
              <button className="mobile-apply-button cta">
                <a href={`mailto:${applyButtonData.url}`}>
                  {applyButtonData.text}
                </a>
              </button>
            )}
          </ul>
        </div>
      </header>
    </>
  );
};
