import { useEffect, useState } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

import "./index.css";

export const Footer = () => {
  const [footerData, setFooterData] = useState([]);
  const [copyrightData, setCopyrightData] = useState([]);

  const builder = ImageUrlBuilder(sanityClient);

  const urlFor = (source) => {
    return builder.image(source);
  };

  const fetchData = async () => {
    try {
      const query = `*[_type == 'footer'][0]`;
      const result = await sanityClient.fetch(query);
      setFooterData(result.content);
      setCopyrightData(result.copyright);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <footer className="footer">
        <div className="content">
          {footerData && footerData.logo && (
            <div className="logo-container">
              <img src={urlFor(footerData.logo.asset._ref).url()} />
            </div>
          )}
          {footerData && footerData.copy && (
            <div className="content-container">
              <div className="contact-container">
                <h3>Contact Us</h3>
                <ul>
                  <li>
                    <h4>Call Us</h4>
                    <a href={`tel:${footerData.copy.phone}`}>
                      {(() => {
                        const phoneNumber = footerData.copy.phone.replace(
                          /\D/g,
                          ""
                        );
                        return `+1 (${phoneNumber.slice(
                          0,
                          3
                        )}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
                      })()}
                    </a>
                  </li>
                  <li>
                    <h4>Email Us</h4>
                    <a href={`mailto:${footerData.copy.email}`}>
                      {footerData.copy.email}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="sitemap-container">
                <h3>Site Map</h3>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  <li>
                    <a href="/gallery">Gallery</a>
                  </li>
                  <li>
                    <a href="/#about-us">About Us</a>
                  </li>
                  <li>
                    <a href="/#testimonials">Testimonials</a>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="copyright-section">
          {copyrightData && (
            <>
              <h3>{copyrightData.text}</h3>
            </>
          )}
        </div>
      </footer>
    </>
  );
};
