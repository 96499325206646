import { useEffect, useState } from "react";
import sanityClient from "../../client";

import "./index.css";

import IMAGE1 from "../../Assets/img1.jpg";
import IMAGE2 from "../../Assets/img2.jpg";
import IMAGE3 from "../../Assets/img3.jpg";
import IMAGE4 from "../../Assets/img4.jpg";
import IMAGE5 from "../../Assets/img5.jpg";
import IMAGE6 from "../../Assets/img6.jpg";

export const Gallery = () => {
  const images = [IMAGE1, IMAGE2, IMAGE3, IMAGE4, IMAGE5, IMAGE6];

  return (
    <>
      <section className="gallery-page">
        <div className="banner">
          <div className="title">
            <h1>Gallery</h1>
            <h3>
              A glimpse into what our dedicated security team looks like in
              action.
            </h3>
          </div>
        </div>
        <div className="gallery-grid-container">
          <div className="gallery-grid">
            {images.map((image, index) => (
              <div key={index} className="gallery-item">
                <img src={image} alt={`Gallery item ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
