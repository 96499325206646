import { useEffect, useState } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

import "./index.css";

export const Header = () => {
  const [pathname] = useState(window.location.href);
  const [menuData, setMenuData] = useState([]);
  const [logo, setLogo] = useState([]);

  const [applyButtonData, setApplyButtonData] = useState([]);

  const builder = ImageUrlBuilder(sanityClient);

  const urlFor = (source) => {
    return builder.image(source);
  };

  const fetchCtaData = async () => {
    try {
      const query = `*[_type == 'fixedCtas'][0]`;
      const result = await sanityClient.fetch(query);
      setApplyButtonData(result.applyButton);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const query = `*[_type == 'header'][0]`;
      const result = await sanityClient.fetch(query);
      setLogo(result.logo);
      const combinedMenuItems = [
        ...result.menu.leftSideItems,
        ...result.menu.rightSideItems,
      ];
      setMenuData(combinedMenuItems);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCtaData();
    fetchData();
  }, []);

  return (
    <>
      <header className="header">
        {logo && logo.asset && (
          <a href="/">
            <img className="logo" src={urlFor(logo.asset._ref).url()} />
          </a>
        )}
        <nav>
          <ul className="menu-list">
            {menuData &&
              menuData.map((item) => {
                const isGalleryOrServices =
                  window.location.href.includes("/gallery") ||
                  window.location.href.includes("/services");

                const isAboutUsOrTestimonials =
                  item.menuItemString === "about us" ||
                  item.menuItemString === "testimonials";

                // if (isGalleryOrServices && isAboutUsOrTestimonials) {
                //   return null;
                // }

                return (
                  <li>
                    <a
                      href={
                        item.menuItemAnchor.current === "gallery"
                          ? "/gallery"
                          : item.menuItemAnchor.current === "/"
                          ? "/"
                          : item.menuItemAnchor.current === "services"
                          ? "/services"
                          : `/#${item.menuItemAnchor.current}`
                      }
                    >
                      {item.menuItemString}
                    </a>
                  </li>
                );
              })}
          </ul>
        </nav>
        {applyButtonData && (
          <button className="apply-button cta">
            <a href={applyButtonData.url}>{applyButtonData.text}</a>
          </button>
        )}
      </header>
    </>
  );
};
