import { useEffect } from "react";
import { Hero } from "../../Components/Hero";
import { PostHero } from "../../Components/PostHero";
import { Services } from "../../Components/Services";
import { FAQ } from "../../Components/FAQ";
import { Testimonials } from "../../Components/Testimonials";

export const Home = () => {
  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, []);

  return (
    <>
      <Hero />
      <PostHero />
      <Services />
      <Testimonials />
      <FAQ />
    </>
  );
};
