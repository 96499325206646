import { useEffect, useState } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

import "./index.css";

import ARROW_DOWN from "../../Assets/Icons/arrow-down.svg";

export const Hero = () => {
  //   const [data, setData] = useState([]);

  //   const builder = ImageUrlBuilder(sanityClient);

  //   const urlFor = (source) => {
  //     return builder.image(source);
  //   };

  //   const fetchData = async () => {
  //     try {
  //       const query = `*[_type == 'hero'][0]`;
  //       const result = await sanityClient.fetch(query);
  //       setData(result);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   useEffect(() => {
  //     fetchData();
  //   }, []);

  return (
    <>
      <section className="hero">
        <div className="overlay" />
        <div className="text-container">
          <h1>Jungle Lion Secuirty</h1>
          <h3>
            We help you to secure all the assets of your organization; services,
            infrastructure and data
          </h3>
          <h4>
            <a href="#contact">Contact Us</a>
          </h4>
        </div>
      </section>
    </>
  );
};
