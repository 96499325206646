import { useEffect, useState, useRef } from "react";
import sanityClient from "../../client";
import emailjs from "@emailjs/browser";

import "./index.css";

const subjectData = [
  "Personal Security",
  "Building & Security",
  "Special Event Security",
  "Professional Uniformed Staff",
  "Package Acceptance",
];

export const Contact = () => {
  const [data, setData] = useState([]);
  const [width] = useState(window.innerWidth);
  const formRef = useRef();

  const fetchData = async () => {
    try {
      const query = `*[_type == 'contact'][0]`;
      const result = await sanityClient.fetch(query);
      setData(result.contact);
    } catch (error) {
      console.error(error);
    }
  };

  const [sendSuccess, setSendSuccess] = useState("");
  const [formData, setFormData] = useState({
    from_name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formRef.current,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      )
      .then((response) => {
        setFormData({
          from_name: "",
          email: "",
          subject: "",
          message: "",
        });
        setSendSuccess("success");
      })
      .catch((error) => {
        setSendSuccess("error");
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <section id="contact" className="contact">
        <div className="container">
          <div className="section-text">
            {data && data.title && <h1>{data.title}</h1>}
            <h2>
              At Jungle Lion Security, we listen to our client’s unique security
              challenges and develop a customized program to suit their
              individual situation, needs, and budget.{" "}
              <strong>Contact us today for a free consultation.</strong>
            </h2>
          </div>
          <div className="contact-form-container">
            <form onSubmit={handleSubmit} ref={formRef}>
              <div className="top-row">
                <div>
                  <label htmlFor="from_name">Name *</label>
                  <input
                    type="text"
                    id="name"
                    name="from_name"
                    value={formData.from_name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="email">Email *</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div>
                <label htmlFor="subject">Subject *</label>
                <select
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled>
                    Select a subject
                  </option>
                  {subjectData.map((subject, index) => {
                    return (
                      <option key={index} value={subject}>
                        {subject}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="message-container">
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder="Message *"
                  required
                  rows={width >= 768 ? 6 : 3}
                ></textarea>
              </div>
              <button type="submit">Submit</button>
            </form>
            <div className={`message-status ${sendSuccess ? "show" : ""}`}>
              <p className="success-message">
                {sendSuccess === "success" &&
                  "Thank you, your inquiry has been succesfully delivered. We will contact you shortly."}
                {sendSuccess === "error" &&
                  "Sorry, there has been an error. Please email us directly at inquiries@jungleliongroup.com"}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
