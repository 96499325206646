import { useEffect, useState } from "react";
import sanityClient from "../../client";
import ImageUrlBuilder from "@sanity/image-url";

import { MarketsSectors } from "../../Components/MarketsSectors";

import "./index.css";

export const ServicesPage = () => {
  const [data, setData] = useState([]);

  const builder = ImageUrlBuilder(sanityClient);

  const urlFor = (source) => {
    return builder.image(source);
  };

  const fetchData = async () => {
    try {
      const query = `*[_type == 'services'][0]`;
      const result = await sanityClient.fetch(query);
      setData(result.servicesPage);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();

    if (window.location.hash) {
      const id = window.location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, []);

  return (
    <>
      <section className="services-page">
        <div className="banner">
          <div className="title">
            <h1>Services</h1>
            <h3>Ensuring your safety and protection through our services.</h3>
            <a href="/services#contact">Contact Us for a Quote</a>
          </div>
        </div>
        <div className="description-container">
          <h1>Jungle Lion Security Offers</h1>
          <h3>
            A comprehensive range of elevated security services designed to
            protect individuals, properties, and events, Our team of highly
            trained professionals is commited to ensuring your safety and peace
            of mind. Here are the key services we offer:
          </h3>
        </div>
        <div className="services-list-container">
          <div className="services-list">
            <ul>
              <li id="personal-security">
                <h2>Personal Security: </h2>
                <p>
                  We provide premium personal security services tailored for
                  executives, high-profile individuals, and celebrities.
                </p>
              </li>
              <li id="building-and-site-security">
                <h2>Building & Site Security: </h2>
                <p>
                  Safeguard your corporate offices, residential properties,
                  venues, and more with our reliable building and site security
                  solutions.
                </p>
              </li>
              <li id="special-event-security">
                <h2>Special Event Security: </h2>
                <p>
                  Whether you are hosting a corporate gathering, private
                  function, or high-profile event, our specialized team excels
                  at providing comprehensive safety planning and onsite
                  security. We secrure entrances and exits, manage guest lists,
                  emply crowd control measures, and guarantee the physical
                  well-being of all attendees.
                </p>
              </li>
              <li>
                <h2>Professional Uniformed Staff: </h2>
                <p>
                  At JUNGLE LION SECURITY, we believe in maintaining a
                  professional and dignified appearance.
                </p>
              </li>
              <li>
                <h2>State-of-the-Art Equipment: </h2>
                <p>
                  To deliver exceptional security solutions, we equip our staff
                  with the latest tools and technology.
                </p>
              </li>
              <li>
                <h2>Secure Access & Logistics: </h2>
                <p>
                  We prioritize the safe entrance and exit of our clients from
                  the premises. With our expertise, you can rest assured that
                  your movements will be carefully monitored, unauthorized
                  access will be prevented, and your security will always be
                  maintained.
                </p>
              </li>
              <li>
                <h2>Package Acceptance & Delivery: </h2>
                <p>
                  Our dedicated guards are capable of accepting packages from
                  couriers and efficiently delivering them to specific areas
                  on-site.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <MarketsSectors />
      </section>
    </>
  );
};
